import React from "react";
import {Page, Text, Image, Document, StyleSheet, View} from "@react-pdf/renderer";

export default function PDFFile(props) {
    console.log(props)
    const styles = StyleSheet.create({
        body:{
            paddingTop:35,
            paddingBottom:65,
            paddingHorizontal:35,
        },
        titulo:{
            fontSize:12,
            textAlign:"justify",
            fontWeight:"bold",
            marginBottom:"5px"
        },
        alternativas:{
            fontSize:10,
            marginBottom:"5px",
            marginRight:20
        },
        header:{
            fontSize:14,
            marginBottom:20,
            textAlign:"center",
            fontWeight:"bold",
            color:"grey",
        },
        pageNumber:{
            position:"absolute",
            fontSize:12,
            bottom:30,
            left:0,
            right:0,
            textAlign:"center",
            color:"grey",
        },
        box:{
            border:"solid",
            borderWidth:1,
            borderColor:"grey",
            padding:10,
            borderBottomLeftRadius:3,
            borderBottomRightRadius:3,
            borderTopLeftRadius:3,
            borderTopRightRadius:3,
            marginBottom:3
        },
        boxAlternativas: {
            display: "flex",
            flexDirection: "row",
            flexWrap:"wrap",
            paddingLeft:10
        },
        boxAlternativas2: {
            paddingLeft:10
        },
        boxPreQuestao:{
            border:"solid",
            borderWidth:1,
            borderColor:"grey",
            padding:2,
            borderBottomLeftRadius:3,
            borderBottomRightRadius:3,
            borderTopLeftRadius:3,
            borderTopRightRadius:3,
            marginBottom:3,
            backgroundColor:"grey"
        },
        textoPreQuestao:{
            fontSize:12,
            color:"white",
            textAlign:"center"
        }
    });

    return (
        <Document>
            <Page size="A4" style={styles.body}>
                <Text style={styles.header}>{props.formulario.titulo}</Text>
                {
                    props.questoes.map(itemQuestao => {
                        return(
                            <View>
                                {
                                    itemQuestao.textoPreQuestao ?
                                    <View style={styles.boxPreQuestao}>
                                        <Text style={styles.textoPreQuestao}>{itemQuestao.textoPreQuestao}</Text>
                                    </View>
                                    :null
                                }
                                <View style={styles.box}>
                                    <Text style={styles.titulo}>
                                        {itemQuestao.questao}
                                    </Text>
                                    <View style={!itemQuestao.vertical ? styles.boxAlternativas:styles.boxAlternativas2}>
                                    {
                                        itemQuestao.alternativas.map(itemAlternativa => {
                                            return (
                                                <Text style={styles.alternativas}>
                                                    {itemAlternativa.descricao}
                                                </Text>
                                            )
                                        })
                                    }
                                    </View> 
                                </View>
                            </View>
                        )
                    })
                }
                <Text style={styles.pageNumber} render={({pageNumber,totalPages}) => `${pageNumber} / ${totalPages}`} fixed></Text>
            </Page>
        </Document>
    )
}