import Banner from '../assets/banner-inicio.png';
import LogoWebSai from '../assets/logo.png';
import { MdLogin } from "react-icons/md";
import Logocps from '../components/Logocps.js';
import React, {useState} from 'react';
import axios from 'axios';
import validator from 'validator';
import { jwtDecode } from "jwt-decode";
import { RotatingLines } from 'react-loader-spinner';
import Swal from 'sweetalert2'
import InputMask from 'react-input-mask';

export default function RedefinirSenha(){

    const [inputs, setInputs] = useState({});
    const [invalido, setInvalido] = useState('d-none');
    const [loading, setLoad] = useState(false);

    const [formshow, setFormShow] = useState('');
    const [formredefinir, setFormRedefinir] = useState('d-none');
    const [errorMessage, setErrorMessage] = useState('');
    const [errorConfirm, setErrorConfirm] = useState('');

    //const [token, setToken] = useState('');
    const [nome, setNome] = useState('');
    const [cpf, setCPF] = useState('');


    const handledChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        setInputs(values => ({...values,[name]:value}));
        setInvalido('d-none');
    }

    const handleSubmitShow = (event) => {
        event.preventDefault();
        show();
    }

    const handleSubmitRedefinir = (event) => {
        event.preventDefault();
        redefirnir();
    }

    const redefirnir = () => {
        if (validator.isStrongPassword(inputs.password, {
            minLength: 6, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
          })) {

            if(inputs.password !== inputs.confirmar)
            {
                setErrorMessage('');
                setErrorConfirm('A confirmação de senha não confere');
            }
            else 
            {
                setLoad(true);
                setErrorConfirm('');
                axios.put(process.env.REACT_APP_API + 'Autoriza/Change',inputs, {headers:{Authorization:"Bearer "+process.env.REACT_APP_JWT}})
                .then((response => {
                    setLoad(false);
                    Swal.fire({
                        title: 'Senha atualizada com sucesso!',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 2000
                        }).then(() =>
                        window.location.href = process.env.REACT_APP_HOST
                        ) 
                }));
            }
          } else {
            setErrorMessage('Sua senha deve ter no mínimo 6 caracteres, uma letra minúscula, uma maiúscula, um número e um caracter especial')
          }
    }

    const show = () => {
        if(inputs.nasc !== undefined && inputs.nasc.replaceAll('_','').replaceAll('/','').length === 8) inputs.dtNascimento = inputs.nasc.split('/')[2]+'-'+inputs.nasc.split('/')[1]+'-'+inputs.nasc.split('/')[0];
        else 
        {
            setInvalido('');
            setLoad(false);
            return;
        }
        setLoad(true);
        axios.post(process.env.REACT_APP_API + 'Autoriza/OtherLogin',inputs, {headers:{Authorization:"Bearer "+process.env.REACT_APP_JWT}})
        .then((response => {
            if(response.data.statusCode === 200) {
                setFormRedefinir('');
                setFormShow('d-none')
                //setToken(response.data.value.token);
                let decodeToken = jwtDecode(response.data.value.token);
                setNome(decodeToken.NomeCompleto)
                setCPF(decodeToken.CPF);
                setLoad(false);
            }
            else {
                setInvalido('');
                setLoad(false);
            }
        })).catch((error) => {
            setLoad(false);
        });
    }

    return (
        <div className="container col-md-9 centro p-2">
            <div className="row m-2 shadow">
                <div className="col-12 rounded bg-white">
                    <div className="row">
                        <div className="col-sm-6 p-3">
                            <div className="d-flex justify-content-center">
                                <img src={LogoWebSai} alt="Logo" style={{width:150}}/>
                            </div>
                            <p className="text-center fs-5 fw-bolder mb-0">Redefinir Senha</p>
                            <p className='text-center mb-0'>Informe os dados abaixo para redefinir sua senha</p>
                            <div className="separator m-3"></div>
                            <form className={"col-8 mx-auto "+formshow} onSubmit={handleSubmitShow}>
                                <div className="mb-2">
                                    <label htmlFor="userName">CPF</label>
                                    <input type="text" name="userName" placeholder="Informe seu CPF" onChange={handledChange} className="form-control" required/>
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="nasc">Data de Nascimento</label>
                                    <InputMask name="nasc" mask="99/99/9999" onChange={handledChange} className="form-control" required placeholder="00/00/0000"></InputMask>
                                </div>
                                <div className="d-grid gap-2">
                                    <button type="submit" className="btn btn-custom mt-3" disabled={loading ? true : false}>
                                        <MdLogin/> {!loading ? 'Redefinir senha': 'Carregando... '}
                                        <RotatingLines
                                            strokeColor="black"
                                            strokeWidth="5"
                                            animationDuration="0.75"
                                            width="25"
                                            visible={loading}
                                        />
                                    </button>
                                </div>
                                <div className={"alert alert-warning text-center mt-2 "+invalido}>
                                    Não foi possível localizar o seu cadastro para redefinir sua senha. Verifique seu CPF e data de nascimento.
                                </div>
                            </form>

                            <form className={"col-8 mx-auto "+formredefinir} onSubmit={handleSubmitRedefinir}>
                                <div className="mb-2">
                                    <label htmlFor="cpf">Nome</label>
                                    <input type="text" name="nome" value={nome} className="form-control" disabled/>
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="userName">CPF</label>
                                    <input type="text" name="userName" value={cpf} className="form-control" disabled/>
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="">Nova senha</label>
                                    <input type="password" name="password" onChange={handledChange} className="form-control" required/>
                                    <small className="text-danger">{errorMessage}</small>
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="">Confirmar senha</label>
                                    <input type="password" name="confirmar" onChange={handledChange} className="form-control" required/>
                                    <small className="text-danger">{errorConfirm}</small>
                                </div>
                                <div className="d-grid gap-2">
                                    <button type="submit" className="btn btn-custom mt-3" disabled={loading ? true : false}>
                                        <MdLogin/> {!loading ? 'Redefinir senha': 'Carregando... '}
                                        <RotatingLines
                                            strokeColor="black"
                                            strokeWidth="5"
                                            animationDuration="0.75"
                                            width="25"
                                            visible={loading}
                                        />
                                    </button>
                                </div>
                            </form>
                            <div className="separator m-3">ou</div>
                            <p className="text-center">Problemas com acesso? <a href="https://helpdesk.cps.sp.gov.br/acessowebsai.php?websai=0" target="_blank" rel="noreferrer">Obter ajuda</a> ou <a href={process.env.REACT_APP_HOST}>Voltar para o login</a></p>
                            <Logocps/>
                        </div>
                        <div className="col-sm-6 d-flex p-1">
                            <img src={Banner} alt="Logo" className="img-fluid"/>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}