import {BrowserRouter, Route, Routes} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import Swal from 'sweetalert2'
import axios from 'axios';

//páginas
import Login from './pages/Login';
import Home from './pages/Home';
import Questionario from './pages/Questionario';
import Edicoes from './pages/Edicoes';
import Formulario from './pages/Formulario';
import EditarQuestionario from './pages/EditarQuestionario';
import Administracao from './pages/Administracao';
import PageNotFound from './pages/PageNotFound';
import RedefinirSenha from './pages/RedefinirSenha';
import Perfil from './pages/Perfil';
import Permissao from './pages/Permissao';
import Acompanhamento from './pages/Acompanhamento';
import Tabulacao from './pages/Tabulacao';
import Revisar from './pages/Revisar';
import Visualizar from './pages/Visualizar';
import Representantes from './pages/Representantes';
import Informações from './pages/Informacoes';
import Indicadores from './pages/Indicadores';
import Apelidos from './pages/Apelidos';
import ErrorBoundary from "./components/ErrorBoundary";

export default function App() {

  const [token, setToken] = useState(false);
  const queryParameters = new URLSearchParams(window.location.search)
  const [perfis, setPerfis] = useState([]);
  var carregou = false;

  useEffect(()=> {
    if(!carregou) {
      if(sessionStorage.getItem('token')) {
        if(sessionStorage.getItem('perfis')) setPerfis(JSON.parse(sessionStorage.getItem('perfis')));
        setToken(true);
      }
      else loginMS();
      carregou = true;
    }
  }, []);

  const loginMS = () => {
    if(queryParameters.get("token")) //login MS existe?
    {
      Swal.fire({
          title: 'Aguarde...',
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
              Swal.showLoading();
          }
      });

      var cpf = jwtDecode(queryParameters.get('token')).cpf;
      var dados = {userName:cpf};

      axios.post(process.env.REACT_APP_API + 'Autoriza/OtherLogin',dados, {headers:{Authorization:"Bearer "+process.env.REACT_APP_JWT}})
      .then(function(response) {
          if(response.data.statusCode === 200)
          {
            sessionStorage.setItem("token", response.data.value.token);
            carregarPerfis(response.data.value.token);
            setToken(true); 
          } 
          else
          {
            sessionStorage.clear();
            setToken(false);
            Swal.close();
            window.location.href = process.env.REACT_APP_HOST+"login";
          }
      }).catch((error) => {
          sessionStorage.clear();
          Swal.close();
          window.location.href = process.env.REACT_APP_HOST+"login";
      })
    }
  }

  const carregarPerfis = (tokenId) => {
    axios.get(process.env.REACT_APP_API + 'Perfis/c4e725ed-008d-44b7-a7f0-b0b74e46c044', {headers:{Authorization:"Bearer "+tokenId}})
    .then((response => {
        if(response.data.statusCode !== 400) {
          var perfis = [];
          response.data.value.perfis.forEach((item, key) => {
              //perfis.push(item.descricao);
              perfis.push(item.sistemaGrupoId)
          })
          sessionStorage.setItem("perfis", JSON.stringify(perfis));
        }
        window.location.href = process.env.REACT_APP_HOST;
        Swal.close();
      })).catch((error) => {
        sessionStorage.clear();
        Swal.close();
        window.location.href = process.env.REACT_APP_HOST+"login";
    });
  }

  return (
    <ErrorBoundary>
      <BrowserRouter>
        <Routes>
          <Route path='/login/*' element={<Login/>}></Route>
          <Route path='/redefinir' element={<RedefinirSenha/>}></Route>
          <Route path='/' element={token ? <Home/> : <Login/>}></Route>
          <Route path='/questionario' element={token ? <Questionario/> : <Login/>}></Route>
          {
            //Administrador do Websai e Apoio do Websai
            perfis.indexOf('8619b556-43f6-43c7-9c42-142c8d3f4f39') > -1 || perfis.indexOf('71cca79b-961c-408c-aa70-50144e8b1702') > -1 
            ? 
            <Route path='/edicoes' element={token ?<Edicoes/> : <Login/>}></Route>
            :null
          }
          {
            //Administrador do Websai e Apoio do Websai
            perfis.indexOf('8619b556-43f6-43c7-9c42-142c8d3f4f39') > -1 || perfis.indexOf('71cca79b-961c-408c-aa70-50144e8b1702') > -1 
            ? 
            <Route path='/formularios' element={token ?<Formulario/> : <Login/>}></Route>
            :null
          }
          {
            //Administrador do Websai e Apoio do Websai
            perfis.indexOf('8619b556-43f6-43c7-9c42-142c8d3f4f39') > -1 || perfis.indexOf('71cca79b-961c-408c-aa70-50144e8b1702') > -1 
            ? 
            <Route path='/editar-questionario' element={token ?<EditarQuestionario/> : <Login/>}></Route>
            :null
          }
          <Route path='/revisar' element={token ?<Revisar/> : <Login/>}></Route>
          <Route path='/visualizar' element={token ?<Visualizar/> : <Login/>}></Route>
          {
            /* Administrador do Websai, Apoio do Websai, Diretor de Unidade Etec,
            Diretor de Unidade FATEC, Diretor de Serviço ETEC, Diretor de Serviço FATEC,
            Representante de Unidade ETEC, Representante de Unidade FATEC*/
            perfis.indexOf('8619b556-43f6-43c7-9c42-142c8d3f4f39') > -1 || perfis.indexOf('71cca79b-961c-408c-aa70-50144e8b1702') > -1 
            || perfis.indexOf('cad3d03e-f5f1-42a6-b502-b522075e1670') > -1 || perfis.indexOf('454b19f4-fa9e-49b7-89c8-4fa68624772a') > -1 
            || perfis.indexOf('b3a10791-a4a0-461a-82ec-89007b68a3f3') > -1 || perfis.indexOf('44a0c8e3-6b85-43b4-a7fb-63c709124a57') > -1  
            || perfis.indexOf('7d3c7717-199a-419b-8eeb-da095da03ac0') > -1 || perfis.indexOf('16783ac8-74fa-494b-b876-cf662f96076e') > -1 
            ?
            <Route path='/administracao' element={token ?<Administracao/> : <Login/>}></Route>
            :null
          }
          {<Route path='/perfil' element={token ?<Perfil/> : <Login/>}></Route>}
          {
            //Administrador do Websai e Apoio do Websai
            perfis.indexOf('8619b556-43f6-43c7-9c42-142c8d3f4f39') > -1 || perfis.indexOf('71cca79b-961c-408c-aa70-50144e8b1702') > -1 
            ? 
            <Route path='/permissoes' element={token ?<Permissao/> : <Login/>}></Route>
            :null
          }
          {
            /* Administrador do Websai, Apoio do Websai, Diretor de Unidade Etec,
            Diretor de Unidade FATEC, Diretor de Serviço ETEC, Diretor de Serviço FATEC,
            Representante de Unidade ETEC, Representante de Unidade FATEC*/
            perfis.indexOf('8619b556-43f6-43c7-9c42-142c8d3f4f39') > -1 || perfis.indexOf('71cca79b-961c-408c-aa70-50144e8b1702') > -1 
            || perfis.indexOf('cad3d03e-f5f1-42a6-b502-b522075e1670') > -1 || perfis.indexOf('454b19f4-fa9e-49b7-89c8-4fa68624772a') > -1 
            || perfis.indexOf('b3a10791-a4a0-461a-82ec-89007b68a3f3') > -1 || perfis.indexOf('44a0c8e3-6b85-43b4-a7fb-63c709124a57') > -1  
            || perfis.indexOf('7d3c7717-199a-419b-8eeb-da095da03ac0') > -1 || perfis.indexOf('16783ac8-74fa-494b-b876-cf662f96076e') > -1 
            ?
            <Route path='/acompanhamento' element={token ?<Acompanhamento/> : <Login/>}></Route>
            :null
          }
          {
            /* Administrador do Websai, Apoio do Websai, Diretor de Unidade Etec,
            Diretor de Unidade FATEC, Diretor de Serviço ETEC, Diretor de Serviço FATEC,
            Representante de Unidade ETEC, Representante de Unidade FATEC*/
            perfis.indexOf('8619b556-43f6-43c7-9c42-142c8d3f4f39') > -1 || perfis.indexOf('71cca79b-961c-408c-aa70-50144e8b1702') > -1 
            || perfis.indexOf('cad3d03e-f5f1-42a6-b502-b522075e1670') > -1 || perfis.indexOf('454b19f4-fa9e-49b7-89c8-4fa68624772a') > -1 
            || perfis.indexOf('b3a10791-a4a0-461a-82ec-89007b68a3f3') > -1 || perfis.indexOf('44a0c8e3-6b85-43b4-a7fb-63c709124a57') > -1  
            || perfis.indexOf('7d3c7717-199a-419b-8eeb-da095da03ac0') > -1 || perfis.indexOf('16783ac8-74fa-494b-b876-cf662f96076e') > -1 
            ?
            <Route path='/tabulacao' element={token ?<Tabulacao/> : <Login/>}></Route>
            :null
          }
          {
            /* Administrador do Websai, Apoio do Websai, Diretor de Unidade Etec,
            Diretor de Unidade FATEC, Diretor de Serviço ETEC, Diretor de Serviço FATEC*/
            perfis.indexOf('8619b556-43f6-43c7-9c42-142c8d3f4f39') > -1 || perfis.indexOf('71cca79b-961c-408c-aa70-50144e8b1702') > -1 
            || perfis.indexOf('cad3d03e-f5f1-42a6-b502-b522075e1670') > -1 || perfis.indexOf('454b19f4-fa9e-49b7-89c8-4fa68624772a') > -1 
            || perfis.indexOf('b3a10791-a4a0-461a-82ec-89007b68a3f3') > -1 || perfis.indexOf('44a0c8e3-6b85-43b4-a7fb-63c709124a57') > -1  
            ?
            <Route path='/representantes' element={token ?<Representantes/> : <Representantes/>}></Route>
            :null
          }
          {
            //Administrador do Websai
            perfis.indexOf('8619b556-43f6-43c7-9c42-142c8d3f4f39') > -1
            ?
            <Route path='/informacoes' element={token ?<Informações/> : <Informações/>}></Route>
            :null
          }
          {
            //Administrador do Websai e Apoio do Websai
            perfis.indexOf('8619b556-43f6-43c7-9c42-142c8d3f4f39') > -1 || perfis.indexOf('71cca79b-961c-408c-aa70-50144e8b1702') > -1 
            ?
            <Route path='/indicadores' element={token ?<Indicadores/> : <Indicadores/>}></Route>
            :null
          }
          {
            //Administrador do Websai e Apoio do Websai
            perfis.indexOf('8619b556-43f6-43c7-9c42-142c8d3f4f39') > -1 || perfis.indexOf('71cca79b-961c-408c-aa70-50144e8b1702') > -1 
            ?
            <Route path='/apelidos' element={token ?<Apelidos/> : <Apelidos/>}></Route>
            :null
          }
          
          <Route path='*' element={<PageNotFound/>}></Route>
        </Routes>
      </BrowserRouter>
    </ErrorBoundary>
  );
}
