import Banner from '../assets/banner-inicio.png';
import LogoWebSai from '../assets/logo.png';
import { FaWindows } from 'react-icons/fa';
import { MdLogin } from "react-icons/md";
import Logocps from '../components/Logocps.js';
import React, {useState} from 'react';
import axios from 'axios';
import { RotatingLines } from 'react-loader-spinner';

export default function Login(){

    const [inputs, setInputs] = useState({});
    const [invalido, setInvalido] = useState('d-none');
    const [loading, setLoad] = useState(false);

    const handledChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values,[name]:value}));
        setInvalido('d-none');
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        logar();
    }

    const logar = () => {
        setLoad(true);
        axios.post(process.env.REACT_APP_API + 'Autoriza/login/',inputs, {headers:{Authorization:"Bearer "+process.env.REACT_APP_JWT}})
        .then((response => {
           if(response.data.value.token)
           {
                sessionStorage.setItem("token", response.data.value.token);
                carregarPerfis(); //carregar perfis
           }
           else 
           {
                setLoad(false);
                setInvalido('');
           }
        }));
    }

    const carregarPerfis = () => {
        axios.get(process.env.REACT_APP_API + 'Perfis/c4e725ed-008d-44b7-a7f0-b0b74e46c044', {headers:{Authorization:"Bearer "+sessionStorage.getItem('token')}})
        .then((response => {
            if(response.data.statusCode !== 400) {
                var perfis = [];
                response.data.value.perfis.map((item, key) => 
                    //perfis.push(item.descricao)
                    perfis.push(item.sistemaGrupoId)
                )
                sessionStorage.setItem("perfis", JSON.stringify(perfis));
            }
            window.location.href = process.env.REACT_APP_HOST;
        }));
    }

    return (
        <div className="container col-md-9 centro p-2">
            <div className="row m-2 shadow">
                <div className="col-12 rounded bg-white">
                    <div className="row">
                        <div className="col-sm-6 p-3 mt-4">
                            <div className="d-flex justify-content-center">
                                <img src={LogoWebSai} alt="Logo" style={{width:150}}/>
                            </div>
                            <p className="text-center fs-5 fw-bolder mb-0">Acesso à plataforma de pesquisa</p>
                            <p className='text-center mb-0'>Realize seu acesso de forma facilitada com sua conta institucional</p>
                            <div className="d-grid gap-2 col-8 mx-auto">
                                <a href={"https://esb.cps.sp.gov.br/sso-back/v1/login?callback="+process.env.REACT_APP_HOST+"login&callback_error=@Model.LoginMsBaseUrl/acesso/loginmserror"} className="btn btn-custom mt-3"><FaWindows/> Entrar com sua conta Microsoft</a>
                            </div>
                            <div className="separator m-3">ou</div>
                            <form className="col-8 mx-auto" onSubmit={handleSubmit}>
                                <p className="text-center fw-bolder">Utilize o seu CPF e senha.</p>
                                <div className="mb-2">
                                    <label htmlFor="UserName">CPF</label>
                                    <input type="text" name="userName" placeholder="Informe seu CPF" onChange={handledChange} className="form-control" required/>
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="">Senha</label>
                                    <input type="password" name="password" placeholder="Informe sua senha" onChange={handledChange} className="form-control" required/>
                                </div>
                                <div className="d-grid gap-2">
                                    <button type="submit" className="btn btn-custom mt-3" disabled={loading ? true : false}>
                                        <MdLogin/> {!loading ? 'Entrar com CPF e senha' : 'Carregando... '}
                                        <RotatingLines
                                        strokeColor="black"
                                        strokeWidth="5"
                                        animationDuration="0.75"
                                        width="25"
                                        visible={loading}
                                        />
                                    </button>
                                    <span className="text-center">* No primeiro acesso clique em Redefinir Senha</span>
                                </div>
                                <div className={"alert alert-warning text-center mt-2 "+invalido}>
                                    CPF ou senha inválidos
                                </div>
                            </form>
                            <div className="separator m-3">ou</div>
                            <p className="text-center">Problemas com acesso? <a href={process.env.REACT_APP_HOST+"redefinir"}>Redefinir senha</a> ou <a href="https://helpdesk.cps.sp.gov.br/acessowebsai.php?websai=0" target="_blank" rel="noreferrer">Obter ajuda</a></p>
                            <Logocps/>
                        </div>
                        <div className="col-sm-6 d-flex p-1">
                            <img src={Banner} alt="Logo" className="img-fluid"/>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}