import { BiHelpCircle, BiHomeAlt2, BiUserCircle, BiCog, BiExit } from "react-icons/bi";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { jwtDecode } from "jwt-decode";
import {Link} from 'react-router-dom';
import { useEffect, useState } from "react";

export default function BarraNavegacao()
{
    const decodedToken = jwtDecode(sessionStorage.getItem('token'));
    const [perfis, setPerfis] = useState([]);

    const logout = () => {
        sessionStorage.clear();
        window.location.href = process.env.REACT_APP_HOST;
    }

    useEffect(()=> {
        setPerfis(JSON.parse(sessionStorage.getItem('perfis')));
    }, []);

    return (
        <Navbar expand="lg" className="background sticky-top">
        <Container>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">
                <Nav.Link as={Link} to="/"><BiHomeAlt2 size={25} className="m-2"/> Início</Nav.Link>
                <Nav.Link href={process.env.REACT_APP_HELPDESK} target="_blank" rel="noreferrer"><BiHelpCircle size={25} className="m-2"/> Ajuda</Nav.Link>
                <Nav.Link as={Link} to="/perfil"><BiUserCircle size={25} className="m-2"/> Perfil</Nav.Link>
                {
                    /* Administrador do Websai, Apoio do Websai, Diretor de Unidade Etec,
                    Diretor de Unidade FATEC, Diretor de Serviço ETEC, Diretor de Serviço FATEC,
                    Representante de Unidade ETEC, Representante de Unidade FATEC*/
                    perfis !== null ?
                    perfis.indexOf('8619b556-43f6-43c7-9c42-142c8d3f4f39') > -1 || perfis.indexOf('71cca79b-961c-408c-aa70-50144e8b1702') > -1 
                    || perfis.indexOf('cad3d03e-f5f1-42a6-b502-b522075e1670') > -1 || perfis.indexOf('454b19f4-fa9e-49b7-89c8-4fa68624772a') > -1 
                    || perfis.indexOf('b3a10791-a4a0-461a-82ec-89007b68a3f3') > -1 || perfis.indexOf('44a0c8e3-6b85-43b4-a7fb-63c709124a57') > -1  
                    || perfis.indexOf('7d3c7717-199a-419b-8eeb-da095da03ac0') > -1 || perfis.indexOf('16783ac8-74fa-494b-b876-cf662f96076e') > -1 
                    ? <Nav.Link as={Link} to="/administracao"><BiCog size={25} className="m-2"/> Administração</Nav.Link> 
                    : null
                    :null
                }
                <Nav.Link onClick={logout}><BiExit size={25} className="m-2"/> Sair</Nav.Link>
            </Nav>
            </Navbar.Collapse>
        </Container>
        </Navbar>
    )
}